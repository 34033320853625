<template>
  <div class="head-box">
    <div class="head">
      <div class="left">
        <img class="logo" src="https://oss-prod.danzhuqiyi.com/website/logo.png" alt="">
        丹朱棋艺
      </div>
      <div class="right">
        <div :class="isCurrent(item) ? 'current' : ''" class="item" v-for="(item, idx) in routerList" :key="idx"
          @click="toPath(item.path)">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Index",
  data() {
    return {
      routerList: [
        {
          title: "首页",
          path: "/index",
          current: ['/index', '/detail']
        },
        // {
        //   title: "帮助中心",
        //   path: "/help",
        //   current: ['/help']
        // },
        {
          title: "关于我们",
          path: "/about",
          current: ['/about']
        },
      ],
    };
  },
  computed: {
    isRouter() {
      let path = this.$route.path;
      return path;
    },
    isCurrent() {
      return (val) => {
        let path = this.$route.path;
        if (val.current.indexOf(path) >= 0) {
          return true
        } else {
          return false
        }
      }
    }
  },
  methods: {
    toPath(path) {
      if (path === this.$route.path) { return; }
      this.$router.push(path);
    },
  },
};
</script>
<style scoped lang='scss'>
.head-box {
  min-width: 100%;
  // background-color: #8fc31f;
  background-color: #FFB2A6;
  // background-image: linear-gradient(to left, #FFB2A6,#ffd6a871 , #FFEFD3);
  display: flex;
  justify-content: center;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: 11.48rem;
  height: 1rem;
  font-size: 0.2rem;
  // border: 1px solid red;

  .left {
    // width: 1.575rem;
    height: 0.7rem;
    // margin-left: 0.1rem;
    // background-image: url("https://oss-prod.danzhuqiyi.com/dz-common/web/icon_logo.png");
    // background-size: 100% 100%;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    font-size: .56rem;
    .logo{
      width: 0.7rem;
      height: 0.7rem;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      margin-right: 0.1rem;
      border-radius: 0.1rem;
    }
  }

  .right {
    display: flex;
    height: 100%;
    // margin-right: -0.12rem;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.2rem;
      cursor: pointer;
      width: 1.2rem;
    }

    .current {
      background-color: #5c800d;
      background-color: #f99f92;
    }
  }
}


@media only screen and (min-width: 501px) and (max-width: 1148px) {
  .head-box {
    width: 11.48rem;

    .left {
      margin-left: 0.2rem;
    }

    .right {
      margin-right: 0.5rem;
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 500px) {
  .head-box {
    width: 3.75rem;
    min-width: 100%;
  }

  .head {
    width: 100%;
    height: 0.5rem;
    font-size: 0.12rem;

    .left {
      // width: 1rem;
      height: 0.4rem;
      margin-left: 0.1rem;
      font-size: .2rem;
      .logo{
        width: 0.3rem;
        height: 0.3rem;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
        margin-right: 0.1rem;
        border-radius: 0.01rem;
      }
    }

    .right {
      display: flex;
      height: 100%;
      margin-right: 0.12rem;

      .item {
        width: 0.7rem;
        margin-left: 0;
      }
    }
  }
}
</style>