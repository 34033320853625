<template>
  <div id="app">
    <head-box />
    <router-view />
    <foot-box />
  </div>
</template>
<script>
import headBox from "./components/head_box/index.vue";
import footBox from "./components/foot_box/index.vue";
export default {
  name: "App",
  data() {
    return {
    };
  },
  components: { headBox, footBox },
};   
</script>
<style lang='scss'>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100px;
  font-family: -apple-system, "PingFang SC", "Hiragino Sans GB", "Noto Serif SC",
    "Microsoft Yahei", "WenQuanYi Micro Hei", "ST Heiti", sans-serif;
}

#app {
  height: 100%;
}

a {
  text-decoration: none;
}

.swiper-pagination-bullet-active {
  background: #ccc 100% !important;
}</style>
