import Vue from 'vue'
import VueRouter from 'vue-router'

const Index = () => import('../views/index')
const Help = () => import('../views/help')
const About = () => import('../views/about')
const Experience = () => import('../views/experience')
const Detail = () => import('../views/detail')


Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Index,
}, {
  path: '/index',
  name: 'index',
  component: Index,
},
{
  path: '/help',
  name: 'help',
  component: Help
},
{
  path: '/about',
  name: 'about',
  component: About
},
{
  path: '/experience',
  name: 'experience',
  component: Experience
},
{
  path: '/detail',
  name: 'detail',
  component: Detail
},{
  path: '/:catchAll(.*)',
  component: Index,
}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router