import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import axios from 'axios'
axios.defaults.baseURL = 'http://s.dzqyh.com:62010/Soft'
axios.defaults.timeout = 3000
Vue.prototype.$Api = axios

// import VueLazyload from 'vue-lazyload'
import lazyLoad from 'vue-lazyload'
Vue.use(lazyLoad) 

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')